@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Inter', 'Noto Sans KR', 'sans-serif';
        @apply text-black100;
    }

    /* START custom pagination  */
    .customPagination ul.pagination {
        @apply flex items-center justify-center bg-white px-4 py-3 sm:px-6;
    }

    .customPagination ul.pagination li:nth-of-type(2) {
        @apply mr-2;
    }

    .customPagination ul.pagination li:nth-last-child(2) {
        @apply ml-2;
    }

    .customPagination ul.pagination li:nth-of-type(1),
    .customPagination ul.pagination li:last-of-type {
        @apply relative inline-flex items-center bg-white px-[.7rem] py-[.35rem] text-sm font-medium text-gray-700 hover:bg-gray-50;
    }

    .customPagination ul.pagination li:nth-last-child(2),
    .customPagination ul.pagination li:nth-of-type(2) {
        @apply relative inline-flex items-center bg-white px-[.875rem] py-[.35rem] text-sm font-medium text-gray-700 hover:bg-gray-50;
    }

    .customPagination ul.pagination li:nth-of-type(1) a,
    .customPagination ul.pagination li:last-of-type a,
    .customPagination ul.pagination li:nth-last-child(2) a,
    .customPagination ul.pagination li:nth-of-type(2) a {
        @apply tablet:text-lg mobile:text-sm;
    }

    .customPagination ul.pagination li:not(:nth-of-type(1)):not(:last-of-type):not(:nth-of-type(2)):not(:nth-last-child(2)) a {
        @apply hover:bg-gray-50 focus:outline-offset-0 px-4 py-2 mobile:px-[12px] mobile:py-[6px] tablet:text-lg mobile:text-sm font-medium text-gray-700 hover:bg-gray-50;
    }

    .customPagination ul.pagination li:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:last-of-type):not(:nth-last-child(2)).active a {
        @apply z-10 bg-primary text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;

    }

    /* END custom pagination  */
    /*  START custom swiper */
    .swiper {
        @apply w-[900px] mobile:w-[95%] h-[240px] -mt-10 !important;
    }

    .customTooltip {
        @apply absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700;
    }

    .noData {
        @apply text-gray-600/90 text-center bg-gray-200/60 rounded-md;
    }
}


:root {
    --swiper-navigation-sides-offset: -10px;
}


.swiper-button-prev {
    background: url(../../public/images/prev.png) no-repeat;
    background-size: contain;
}

.swiper-button-next {
    background: url(../../public/images/next.png) no-repeat;
    background-size: contain;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    display: none;
}

.swiper-pagination-bullet-active {
    background-color: #008A74 !important;
}

.swiper-pagination {
    bottom: 0px !important;
}

.swiper-slide {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around;
}

.swiper-slide > div {
    overflow: visible;
}

.swiper-slide img {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
    transform: translateX('100%') !important;
}

input[type=text].customInput, input:focus {
    appearance: none !important;
    border-color: transparent !important;
    z-index: 100;
    outline: none 0 !important;
}


/*  END custom swiper */

